<template>
  <div class="login">
    <div class="login__container">
      <div>
        <div class="mb-6">
          <div class="login__title">
            Rescue Location Management
          </div>
        </div>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="form.username"
                :rules="usernameRules"
                :placeholder="$t('app.placeholder.username')"
                required
                dense
                outlined />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="form.password"
                :rules="passwordRules"
                :placeholder="$t('app.placeholder.password')"
                required
                dense
                outlined />
            </v-col>
          </v-row>
        </v-form>
        <div class="text-right mb-12">
          <v-btn
            :disabled="isSubmitting"
            color="primary"
            @click="LoginWithEmailPassword()">
            {{ $t('app.btn.signIn') }}
          </v-btn>
        </div>
        <v-row>
          <v-col>
            <v-btn
              :disabled="isSubmitting"
              color="white"
              block
              @click="loginWithGoogle()">
              <img
                src="@/assets/img/google.png"
                width="24"
                height="24"
                class="mr-4" />
              {{ $t('app.btn.signInGoogle') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              :disabled="isSubmitting"
              color="white"
              block
              @click="toLineLogin()">
              <img
                src="@/assets/img/line.png"
                width="24"
                height="24"
                class="mr-4" />
              {{ $t('app.btn.signInLine') }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="login__banner">
      <img
        class="w-100 h-100"
        src="@/assets/img/rescue-backgound.png" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AuthProvider from '@/resources/axios/providers/auth'
import firebase from '@/plugins/firebase'
import { lineConfig } from '@/plugins/line'

export default {
  name: 'Login',
  data () {
    return {
      valid: true,
      isSubmitting: false,
      form: {
        username: '',
        password: ''
      },
      usernameRules: [(v) => !!v || this.$t('app.validate.username')],
      passwordRules: [(v) => !!v || this.$t('app.validate.password')]
    }
  },
  computed: {
    ...mapGetters({
      lineData: 'local/lineData'
    })
  },
  mounted () {
    if (this.lineData?.sub) {
      this.loginWithLine()
    }
  },
  methods: {
    ...mapActions({
      setUser: 'auth/setUser',
      showErrorMessage: 'notification/showErrorMessage',
      closeErrorMessage: 'notification/closeErrorMessage',
      setCurrentPage: 'app/setCurrentPage',
      clearLineData: 'local/clearLineData'
    }),
    async loginWithLine () {
      try {
        if (this.isSubmitting) { return }
        this.isSubmitting = true
        const res = await AuthProvider.loginWithSocial({
          uuid: this.lineData?.sub,
          method: 'Social'
        })
        if (res) {
          this.showErrorMessage({
            message: this.$t('app.alert.signIn'),
            type: 'success'
          })
          setTimeout(() => {
            this.closeErrorMessage()
          }, 2000)
          this.isSubmitting = false
          this.setUser(res)
          this.clearLineData()
          this.$router.push({ name: 'Location' })
        }
      } catch (error) {
        this.showErrorMessage({
          message: error.message,
          type: 'warning'
        })
        setTimeout(() => {
          this.closeErrorMessage()
        }, 2000)
        this.isSubmitting = false
        console.error(error)
      }
    },
    async loginWithGoogle () {
      try {
        if (this.isSubmitting) { return }
        this.isSubmitting = true
        const { user } = await firebase.auth.signInWithPopup(firebase.provider)
        if (user) {
          const res = await AuthProvider.loginWithSocial({
            uuid: user.uid,
            method: 'Social'
          })
          if (res) {
            this.showErrorMessage({
              message: this.$t('app.alert.signIn'),
              type: 'success'
            })
            setTimeout(() => {
              this.closeErrorMessage()
            }, 2000)
            this.isSubmitting = false
            this.setUser(res)
            this.$router.push({ name: 'Location' })
          }
        }
      } catch (error) {
        this.showErrorMessage({
          message: error.message,
          type: 'warning'
        })
        setTimeout(() => {
          this.closeErrorMessage()
        }, 2000)
        this.isSubmitting = false
        console.log(error)
      }
    },
    toLineLogin () {
      if (this.isSubmitting) { return }
      this.isSubmitting = true
      this.setCurrentPage(this.$route?.name)
      window.location.href = lineConfig.uri
      this.isSubmitting = false
    },
    async LoginWithEmailPassword () {
      try {
        if (this.isSubmitting) { return }
        const valid = this.$refs.form.validate()
        if (valid) {
          this.isSubmitting = true
          const res = await AuthProvider.loginWithEmail({
            username: this.form.username,
            password: this.form.password,
            method: 'Basic'
          })
          if (res) {
            this.showErrorMessage({
              message: this.$t('app.alert.signIn'),
              type: 'success'
            })
            setTimeout(() => {
              this.closeErrorMessage()
            }, 2000)
            this.isSubmitting = false
            this.setUser(res)
            this.$router.push({ name: 'Location' })
          }
        } else {
          this.showErrorMessage({
            message: this.$t('app.alert.required'),
            type: 'warning'
          })
          setTimeout(() => {
            this.closeErrorMessage()
          }, 2000)
        }
      } catch (error) {
        this.showErrorMessage({
          message: error.message,
          type: 'warning'
        })
        setTimeout(() => {
          this.closeErrorMessage()
        }, 2000)
        this.isSubmitting = false
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
  .login {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background: linear-gradient(153.85deg, #1867C0 1.2%, rgba(31, 129, 245, 0.5625) 53.05%, #1867C0 100%);
  }
  .login__container {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EAF0F5;
    border: 1px solid #EAF0F5;
    border-radius: 0px 18px 18px 0px;
  }
  .login__banner {
    width: 40%;
    height: 100vh;
    filter: opacity(0.5);
  }
  .login__title {
    font-size: 32px;
    font-weight: 700;
    color: var(--v-primary-base);
  }
  .login__subtitle {
    font-size: 24px;
    font-weight: 700;
    color: var(--v-primary-base);
  }
@media only screen and (max-width: 991px) {
  .login {
    flex-direction: column;
  }
  .login__container {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    padding: 24px;
  }
  .login__banner {
    display: none;
  }
}
</style>
